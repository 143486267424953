


































import { Component, Vue } from 'vue-property-decorator';

@Component({ name: 'PasswordChanged' })
export default class PasswordChanged extends Vue {
  get origin_url() {
    return sessionStorage.getItem('origin_url') || process.env.VUE_APP_COLUMBUS_WEB_LOGIN_URL;
  }
}
